import classNames from 'classnames';
import React, {ReactElement, useState} from 'react';

import assetPath from 'web/helpers/asset_path';
import {DisplayTotal} from 'web/basket/basket_page';
import useClientSettings from 'web/hooks/useClientSettings';

import {InfoModal} from '../info_modal';
import SavingBanner from '../saving_banner';

const DELIVERY_FEE_KEY = 'deliveryFee';
const GOOD_JOBS_FEE_KEY = 'goodJobsFee';

type TotalRowProps = Omit<DisplayTotal, 'amount'> & {
  className: string;
  selector: string;
  isMembershipOrder?: boolean;
  feeKey: string;
};

const TotalRow = ({
  className,
  formattedAmount,
  info,
  label,
  secondaryLabel,
  selector,
  alert,
  isMembershipOrder,
  feeKey,
}: TotalRowProps): ReactElement => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const onClickInfo = (): void => {
    setIsInfoModalOpen(true);
  };

  const onDismissInfo = (): void => {
    setIsInfoModalOpen(false);
  };

  return (
    <div className={classNames('totals__total-row', className)}>
      {Boolean(secondaryLabel) && (
        <span className="totals__total-secondary-label">{secondaryLabel}</span>
      )}

      <span className="totals__total-label">
        {Boolean(alert) && <span className="totals__total-alert">{alert}</span>}
        {label}
        {info && (
          <div className="totals__total-info">
            <span className="totals__total-info-link" onClick={() => onClickInfo()}>
              <img src={`${assetPath('/img/web/questionmark_web.png')}?auto=format`} />
            </span>
            {isInfoModalOpen && <InfoModal info={info} onClose={onDismissInfo} />}
          </div>
        )}
      </span>

      <span
        className={classNames('totals__total-formatted-amount', selector, {
          'totals__membership-fee':
            [DELIVERY_FEE_KEY, GOOD_JOBS_FEE_KEY].includes(feeKey) && isMembershipOrder,
        })}
      >
        {formattedAmount}
      </span>
    </div>
  );
};

export interface DisplayTotalsProps {
  displayTotals?: DisplayTotal[];
  showDeliveryFee: boolean;
  isMembershipOrder?: boolean;
  totalSavings?: number;
}

export const DisplayTotals = ({
  displayTotals,
  showDeliveryFee,
  isMembershipOrder,
  totalSavings,
}: DisplayTotalsProps): ReactElement => {
  const clientSettings = useClientSettings();
  const displayTotalsToShow = showDeliveryFee
    ? displayTotals
    : displayTotals?.filter(({key}) => key !== DELIVERY_FEE_KEY);

  return (
    <div className="totals">
      {displayTotalsToShow?.map((displayTotal) => (
        <TotalRow
          key={displayTotal.key}
          className={`totals__total-row__${displayTotal.key}`}
          selector={`js-totals__${displayTotal.key}`}
          label={displayTotal.label}
          formattedAmount={displayTotal.formattedAmount}
          secondaryLabel={displayTotal.secondaryLabel}
          info={displayTotal.info}
          alert={displayTotal.alert}
          isMembershipOrder={isMembershipOrder}
          feeKey={displayTotal.key}
        />
      ))}
      {totalSavings != null &&
        totalSavings > 0 &&
        clientSettings.strikethroughPricing.enableWeb && (
          <SavingBanner totalSavings={totalSavings} />
        )}
    </div>
  );
};
