import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {every} from 'lodash';
import {array, arrayOf, bool, func, shape, string, object} from 'prop-types';
import classNames from 'classnames';

import moment from 'infra/moment';
import {humanDayOfWeek} from 'infra/formatters/time';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';
import {actions as modalActions} from 'web/helpers/modal_duck';

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        event.target.id === 'desktop-header__day'
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const CommonDayDropdown = ({children, isSelected, onClick, isAvailable = true, id}) => (
  <button
    type="button"
    className={classNames('button-outline', {
      selected: isSelected,
    })}
    disabled={!isAvailable}
    onClick={(e) => onClick(e)}
    key={id}
    id={id}
  >
    {children}
  </button>
);

CommonDayDropdown.propTypes = {
  isSelected: bool.isRequired,
  onClick: func.isRequired,
  id: string.isRequired,
  isAvailable: bool,
};

const DayChooserPreorderPeriod = ({currentFulfillmentDay, preorderPeriod, onClick}) => {
  const isSelected = () =>
    preorderPeriod.firstPreorderDay <= currentFulfillmentDay &&
    currentFulfillmentDay <= preorderPeriod.endDay;

  const startDayMoment = moment(preorderPeriod.firstPreorderDay);
  const endDayMoment = moment(preorderPeriod.endDay);

  return (
    <CommonDayDropdown
      isSelected={isSelected()}
      onClick={() => onClick(preorderPeriod)}
      id={`preorder-period-select-${preorderPeriod.name}`}
    >
      {`${preorderPeriod.name} Preorder (${startDayMoment.format('M/D')} - ${endDayMoment.format(
        'M/D',
      )}) >`}
    </CommonDayDropdown>
  );
};

DayChooserPreorderPeriod.propTypes = {
  preorderPeriod: shape({
    name: string.isRequired,
    categoryId: string,
    firstPreorderDay: string.isRequired,
    startDay: string.isRequired,
    endDay: string.isRequired,
  }),
  currentFulfillmentDay: string.isRequired,
  fulfillmentDaySummaries: array.isRequired,
  onClick: func.isRequired,
  zipCode: string,
};

const DayChooserDay = ({handleChangeDay, fulfillmentDaySummary, tzid, currentFulfillmentDay}) => {
  const {day} = fulfillmentDaySummary;

  const onClickDay = (event) => {
    event.preventDefault();

    if (!available()) {
      return;
    }

    handleChangeDay(fulfillmentDaySummary.day);
  };

  const available = () => fulfillmentDaySummary.status === 'available';
  const dayText = () => humanDayOfWeek(day, tzid);

  const secondaryText = () => {
    const cases = {
      soldout: '(Sold out)',
      unavailable: `(${fulfillmentDaySummary.unavailableCustomerReason || 'Unavailable'})`,
      closed: '(Unavailable)',
    };

    return cases?.[fulfillmentDaySummary.status] || moment.tz(day, tzid)?.format('M/D');
  };

  return (
    <CommonDayDropdown
      isSelected={available() && day === currentFulfillmentDay}
      onClick={(e) => {
        onClickDay(e);
      }}
      isAvailable={available()}
      id={tzid}
    >
      {`${dayText()} ${secondaryText()}`}
    </CommonDayDropdown>
  );
};

DayChooserDay.propTypes = {
  tzid: string.isRequired,
  currentFulfillmentDay: string.isRequired,
  fulfillmentDaySummary: object.isRequired,
  handleChangeDay: func.isRequired,
};

const DayChooser = ({
  currentFulfillmentDay,
  disabled,
  features,
  fulfillmentDaysByDay,
  fulfillmentDaySummaries,
  handleChangeDay,
  onClickPreorderPeriod,
  openZipModal,
  upcomingPreorderPeriods,
  tzid = 'America/Los_Angeles',
  zipCode,
  category,
}) => {
  useEffect(() => {
    const thanksGivingPreOrderWindow =
      upcomingPreorderPeriods &&
      upcomingPreorderPeriods.find(({categoryId}) => categoryId === 'thanksgiving');
    const isSelected = () => {
      return (
        thanksGivingPreOrderWindow &&
        thanksGivingPreOrderWindow.firstPreorderDay <= currentFulfillmentDay &&
        currentFulfillmentDay <= thanksGivingPreOrderWindow.endDay
      );
    };
    if (category?.id === 'thanksgiving' && thanksGivingPreOrderWindow && !isSelected()) {
      //  setOpenMenu(true);
      setTimeout(() => {
        onClickPreorderPeriod(thanksGivingPreOrderWindow);
      }, 100);
    }
  }, [category]);

  const [openMenu, setOpenMenu] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setOpenMenu(false);
  });

  const allSoldOut = () =>
    every(fulfillmentDaysByDay, (fulfillmentDay) => fulfillmentDay.status !== 'available');

  const isAllSoldOut = allSoldOut();

  const isDaySoldOut =
    fulfillmentDaysByDay && fulfillmentDaysByDay[currentFulfillmentDay]?.status !== 'available';

  const handleZipClick = () => openZipModal();

  const toggleDropdownMenu = () => {
    setOpenMenu(!openMenu);
  };

  const isShoppingForPreorderDay = () =>
    upcomingPreorderPeriods?.find((preorderPeriod) =>
      preorderPeriod.preorderDays.find((day) => day === currentFulfillmentDay),
    );

  const getLinkText = () =>
    `${humanDayOfWeek(currentFulfillmentDay, tzid)}${
      isShoppingForPreorderDay() ? ` ${moment(currentFulfillmentDay).format('M/D')} Preorder` : ''
    }`;

  const linkText = getLinkText();

  const onHandleChangeDay = (e) => {
    handleChangeDay(e);
    setOpenMenu(false);
  };

  const renderDropdownMenuButton = () => {
    return disabled ? (
      <>
        Shopping for <strong data-testid="disabled-day">{linkText}</strong>
        {zipCode && <>in {zipCode}</>}
      </>
    ) : (
      <span className="desktop-header__link dropdown-menu__button js-shop-for-day-menu-button">
        Shopping for{' '}
        <strong
          className={isDaySoldOut ? 'soldout-text' : ''}
          id="desktop-header__day"
          data-testid="day"
          onClick={toggleDropdownMenu}
        >
          <span>{linkText}</span>
          {isDaySoldOut && ' (Sold out)'}
        </strong>{' '}
        {zipCode && (
          <>
            in{' '}
            <strong id="desktop-header__zip" data-testid="zipCode" onClick={handleZipClick}>
              {zipCode}
            </strong>
          </>
        )}
      </span>
    );
  };

  const hidePreorderDays = features?.includes(FeatureFlags.HidePreorderDaySelector) ?? false;

  return (
    <li
      className={classNames('dropdown-menu__container desktop-header__shop-for-day-menu', {
        'desktop-header__shop-for-day-menu-static': disabled,
        'js-shop-for-day-static': disabled,
      })}
    >
      {renderDropdownMenuButton()}
      {!disabled && (
        <div
          className={classNames('dropdown-menu__content', {
            'short-left': linkText.length <= 6,
            'mid-left': linkText.length > 6 && linkText.length < 8,
            'long-left': linkText.length >= 8,
          })}
          ref={ref}
          style={{display: openMenu ? 'block' : 'none'}}
        >
          <h3 className="modal-title-bar__title">
            {isAllSoldOut && 'Sold out for all the available delivery days'}
            {!isAllSoldOut && [
              isShoppingForPreorderDay()
                ? 'Select a preorder delivery day'
                : 'Select a delivery day',
            ]}
          </h3>
          {fulfillmentDaySummaries.map((fulfillmentDaySummary) => (
            <DayChooserDay
              fulfillmentDaySummary={fulfillmentDaySummary}
              currentFulfillmentDay={currentFulfillmentDay}
              tzid={tzid}
              key={fulfillmentDaySummary.day}
              handleChangeDay={(e) => {
                onHandleChangeDay(e);
              }}
            />
          ))}
          {!hidePreorderDays &&
            upcomingPreorderPeriods &&
            upcomingPreorderPeriods.map((preorderPeriod) => (
              <DayChooserPreorderPeriod
                preorderPeriod={preorderPeriod}
                key={preorderPeriod.id}
                currentFulfillmentDay={currentFulfillmentDay}
                fulfillmentDaySummaries={fulfillmentDaySummaries}
                onClick={onClickPreorderPeriod.bind(null, preorderPeriod)}
              />
            ))}
        </div>
      )}
    </li>
  );
};

DayChooser.propTypes = {
  features: arrayOf(string),
  disabled: bool,
  tzid: string,
  currentFulfillmentDay: string.isRequired,
  fulfillmentDaySummaries: arrayOf(object).isRequired,
  fulfillmentDaysByDay: object,
  upcomingPreorderPeriods: arrayOf(
    shape({
      name: string.isRequired,
      firstPreorderDay: string.isRequired,
      startDay: string.isRequired,
      endDay: string.isRequired,
      categoryId: string,
      preorderDays: array,
    }),
  ),
  handleChangeDay: func.isRequired,
  onClickPreorderPeriod: func.isRequired,
  openZipModal: func,
};

export function mapDispatchToProps(dispatch) {
  return {
    openZipModal: (props) => {
      dispatch(modalActions.openModal({modal: 'ZipCodeModal', props}));
    },
  };
}

function mapStateToProps(state) {
  const {category} = state;

  return {
    category,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DayChooser);
