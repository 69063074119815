import React, {ReactElement, ReactNode} from 'react';

import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

export interface MembershipCardProps {
  onClickInfo?: () => void;
  whiteLogo?: boolean;
  title?: string | ReactNode;
}

const MembershipCard = ({onClickInfo, title, whiteLogo}: MembershipCardProps): ReactElement => {
  const clientSettings = useClientSettings();
  const enableNewLogos = clientSettings.enableNewLogos.enableWeb;

  let logo: string;

  if (enableNewLogos) {
    logo = `${assetPath('/img/svg/icons/ge-g-logo-white.svg')}?auto=format`;
  } else if (whiteLogo) {
    logo = `${assetPath('/img/svg/icons/icon_white.svg')}?auto=format`;
  } else {
    logo = `${assetPath('/img/svg/icons/ge-member-white.svg')}?auto=format`;
  }
  return (
    <button
      className={enableNewLogos ? 'membership-card new-membership-card-style' : 'membership-card '}
      type="button"
    >
      <img
        onClick={onClickInfo}
        alt="Membership Card logo"
        data-testid="membership-card-logo"
        src={logo}
        className="membership-card__icon"
        style={{cursor: onClickInfo ? 'pointer' : 'inherit'}}
      />
      {typeof title === 'string' || !title ? (
        <span className="membership-card__title">{title ?? 'Good Eggs Membership'}</span>
      ) : (
        title
      )}
      <i
        data-testid="info-icon"
        className="icon icon-alert-info"
        onClick={onClickInfo}
        style={{cursor: onClickInfo ? 'pointer' : 'inherit'}}
      />
    </button>
  );
};

export default MembershipCard;
