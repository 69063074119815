import {postJson, getJson} from 'web/helpers/api_helpers';

import enqueue from '../enqueue';

export function postUpdateItem({productId, quantity, shouldSubscribe, addToOrderId}, {context}) {
  return enqueue(() =>
    postJson('/api/basket/update-item', {
      body: {productId, quantity, shouldSubscribe, addToOrderId},
      metricsProperties: context,
    }),
  );
}

export function postRemoveItem({productId, addToOrderId}, {context}) {
  return enqueue(() =>
    postJson('/api/basket/remove-item', {
      body: {productId, addToOrderId},
      metricsProperties: context,
    }),
  );
}

export function getTotals({fulfillmentDay, addToOrderId}) {
  let url = `/api/basket/totals?day=${fulfillmentDay}`;
  if (addToOrderId) {
    url += `&addToOrderId=${addToOrderId}`;
  }
  return enqueue(() => getJson(url));
}

export function postDeliveryAddress(deliveryAddress, addressIsConfirmed) {
  return addressIsConfirmed == null
    ? enqueue(() => postJson('/api/basket/address', {body: {deliveryAddress}}))
    : enqueue(() => postJson('/api/basket/address', {body: {deliveryAddress, addressIsConfirmed}}));
}

export function postChangeDay(newDay) {
  return postJson('/api/basket/change-day', {body: {day: newDay}});
}
