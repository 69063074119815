import React from 'react';
import {connect} from 'react-redux';
import Cents from 'goodeggs-money';

import MembershipCard from 'web/components/membership_card';

const ReferralBanner = (props) => {
  const {giftValue, kickbackValue} = props;
  const kickbackValueDollars = new Cents(kickbackValue).toDollars();
  const giftValueDollars = new Cents(giftValue).toDollars();
  const location = '/refer-a-friend';

  return (
    <div className="basket-view__header referral-banner">
      <MembershipCard
        onClickInfo={() => {
          window.location = location;
        }}
        title={
          <p data-testid="referral-banner-text">
            Help us grow the Good Eggs community! Give a friend ${giftValueDollars} off their first
            order, and get ${kickbackValueDollars} off your next one.&nbsp;
            <a data-testid="membership-banner-cta" href={location}>
              Click here
            </a>
          </p>
        }
        whiteLogo
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    kickbackValue: state.referrals.kickbackValue,
    giftValue: state.referrals.giftValue,
  };
}

export default connect(mapStateToProps)(ReferralBanner);
