import {gql} from '@apollo/client';
import {pick} from 'lodash';
import update from 'immutability-helper';
import {getFormSyncErrors, getFormValues, isValid, submit} from 'redux-form';

import client, {sanitizeGraphQLError} from 'web/helpers/graphql_client';
import segmentAnalytics from '@analytics/client';
import {actions as modalActions} from 'web/helpers/modal_duck';
import basketDuck from 'web/helpers/basket_duck';
import {SignUpSuccessModalCta} from 'web/components/sign_up_success_modal';
import {actions as signUpModalActions} from 'web/helpers/sign_up_modal_duck';
import {actions as signInPageActions} from 'web/account/sign_up_page/duck';
import {getSegmentFeature} from 'web/components/social_sign_in/duck';

export const CREATING_USER = 'SIGN_UP_FORM_CREATE_USER_BEGIN';
export const FINISH_CREATING_USER = 'SIGN_UP_FORM_CREATE_USER_FINISH';
export const ERROR_CREATING_USER = 'SIGN_UP_FORM_CREATE_USER_ERROR';

export const UPDATING_USER = 'SIGN_UP_FORM_UPDATE_USER_BEGIN';
export const FINISH_UPDATING_USER = 'SIGN_UP_FORM_UPDATE_USER_FINISH';
export const ERROR_UPDATING_USER = 'SIGN_UP_FORM_UPDATE_USER_ERROR';

export const CLEAR_ERRORS = 'SIGN_UP_FORM_CLEAR_ERRORS';

export const actions = {
  clearErrors: () => ({
    type: CLEAR_ERRORS,
  }),

  submitForm:
    ({rejectUponError = false, allowUpdate = false} = {}, signUpOpts = {}) =>
    (dispatch, getState) => {
      const state = getState();
      dispatch(submit('signUp')); // touch all fields
      const formIsValid = isValid('signUp')(state);

      if (formIsValid) {
        const updateAction = state.user && allowUpdate ? actions.updateUser : actions.registerUser;
        return dispatch(updateAction({rejectUponError}, signUpOpts));
      }

      if (rejectUponError) {
        const fieldErrors = getFormSyncErrors('signUp')(state);
        const error = {type: 'INVALID_FORM', message: 'Form is invalid', details: fieldErrors};
        return Promise.reject(error);
      }

      return Promise.resolve();
    },

  updateUser:
    ({rejectUponError = false} = {}) =>
    async (dispatch, getState) => {
      dispatch({type: UPDATING_USER});

      try {
        const state = getState();
        const {
          data: {
            result: {user},
          },
        } = await updateUser(getFormValues('signUp')(state));

        dispatch({
          type: FINISH_UPDATING_USER,
          user,
        });
      } catch (error) {
        dispatch({
          type: ERROR_UPDATING_USER,
          error,
        });
        if (rejectUponError) {
          return Promise.reject(error);
        }
      }
    },

  registerUser:
    ({rejectUponError = false} = {}, signUpOpts) =>
    async (dispatch, getState) => {
      dispatch({type: CREATING_USER});
      const state = getState();
      const userData = {
        ...getFormValues('signUp')(state),
        ...signUpOpts,
      };

      try {
        const res = await createUser(userData);
        if (!res.data) {
          throw new Error('Unexpected error creating user');
        }

        const newUser = res.data.result.user;
        segmentAnalytics.updateUserId({userId: newUser.id});
        segmentAnalytics.track('accountCreated', {
          email: newUser.email,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          signInMethod: 'goodeggs',
          feature: getSegmentFeature(state.modalViewed),
          pageUrl: window.location.pathname,
        });

        dispatch({
          type: FINISH_CREATING_USER,
          user: newUser,
        });

        dispatch(actions.onSignInSuccessful(newUser));
      } catch (error) {
        dispatch({
          type: ERROR_CREATING_USER,
          error: sanitizeGraphQLError(error),
        });

        if (rejectUponError) {
          return Promise.reject(error);
        }
      }
    },
  onSignInSuccessful: (user) => async (dispatch, getState) => {
    const state = getState();
    const {productId, position, quantity} = state.basket.quantitySelect;
    dispatch(signUpModalActions.setUser(user));
    if (state.showSignUpModalFlow) {
      dispatch(signInPageActions.updateBasket());
      if (position) {
        dispatch(basketDuck.actions.incrementQuantity({productId, position, quantity}));
      } else {
        dispatch(basketDuck.actions.assignQuantity({productId, quantity}));
      }
    }
  },
  showSignUpSuccessModal: () => async (dispatch) => {
    dispatch(
      modalActions.replaceModal({
        modal: 'SignUpSuccessModal',
        modalViewed: {
          pageUrl: window.location.pathname,
          loggedIn: true,
          ctaText: SignUpSuccessModalCta,
        },
      }),
    );
  },
};

const createUser = (input) =>
  client.mutate({
    mutation: gql`
      mutation CreateUser($input: CreateUserInput!) {
        result: createUser(input: $input) {
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
    `,
    variables: {
      input,
    },
  });

const updateUser = (input) =>
  client.mutate({
    mutation: gql`
      mutation UpdateUser($input: UpdateUserInput!) {
        result: updateUser(input: $input) {
          user {
            firstName
            lastName
            email
            phone
          }
        }
      }
    `,
    variables: {
      input: pick(input, ['firstName', 'lastName', 'email', 'phone']),
    },
  });

const initialState = {
  isSaving: false,
  error: null,
};

// Wire this into your page reducer alongside the redux-form reducer.
export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATING_USER:
    case UPDATING_USER:
      return update(state, {isSaving: {$set: true}});

    case FINISH_CREATING_USER:
    case FINISH_UPDATING_USER:
      return update(state, {isSaving: {$set: false}, error: {$set: null}});

    case ERROR_CREATING_USER:
    case ERROR_UPDATING_USER:
      return update(state, {isSaving: {$set: false}, error: {$set: action.error}});

    case CLEAR_ERRORS:
      return update(state, {error: {$set: null}});
  }
  return state;
}
